<template>
  <van-pull-refresh
    v-model="isPullLoading"
    :disabled="fillUpload || fillCarEval || fillContact || fillView"
    @refresh="orderDetail"
  >
    <div class="home-content">
      <!-- 总体信息 -->
      <van-overlay
        :show="pageLoading"
        z-index="1000"
        class="dis_flex ali_it_cen ju_con_cen"
      >
        <van-loading v-show="pageLoading">刷新中...</van-loading>
      </van-overlay>
      <template v-if="bizNo">
        <div class="banner">
          <div class="dis_flex ali_it_cen ju_con_bet font_32 m_t_60">
            <!-- isWechat {{ isWechat }} -->
            <div>
              {{ detail.customerName }}
              <span @click="logout" class="logout">退出</span>
            </div>
            <div>{{ detail.applyProductName }}</div>
          </div>
          <div class="dis_flex ali_it_cen ju_con_cen m_t_48">
            <div class="main-info-bloc dis_flex ali_it_cen">
              <div class="text_ali_cen flex_1">
                <div>
                  <span class="font_numb">{{ detail.applyAmount }}</span
                  ><span class="m_l_10">元</span>
                </div>
                <!-- <div @click="fillUpload = true">申请金额</div> -->
                <div>申请金额</div>
              </div>
              <div class="divider" />
              <div class="text_ali_cen flex_1">
                <div>
                  <span class="font_numb">{{ detail.applyTerm }}</span
                  ><span class="m_l_10">期</span>
                </div>
                <div>申请期限</div>
              </div>
            </div>
          </div>
        </div>

        <div class="apply-info block">
          <div class="info-title">申请信息</div>
          <van-field
            v-if="detail.canEvalVehicle == 'Y'"
            :class="[detail.vehicleEvaluated == 'Y' ? 'end' : '']"
            :placeholder="detail.vehicleEvaluated == 'Y' ? '已完成' : '去评估'"
            label="车辆评估"
            @click="
              handleJump({
                processName: '车辆评估',
                ...detail,
                materialList
              })
            "
            readonly
            :is-link="detail.vehicleEvaluated != 'Y'"
            input-align="right"
          />
          <template v-if="unAppoint">
            <van-field
              v-for="(block, idx) in infosFields"
              :key="idx"
              v-model="block.textvalue"
              :class="[block.complete ? 'end' : '']"
              :label="block.name"
              :placeholder="block.tips"
              @click="openFieldsEdit(block)"
              readonly
              :is-link="!block.complete"
              input-align="right"
            />
          </template>
        </div>

        <div class="quota-block container p_l_24 p_r_24 font_28">
          <!-- <div
            v-for="(item, index) in detail.processInfoList"
            :key="index"
            class="quota-item dis_flex ali_it_cen ju_con_bet"
            @click="handleJump(item)"
          >
            <div>{{ item.processName }}</div>
            <div :class="['home-btn', `home-btn_${item.processStatus}`]">
              {{ item.processStatus == "1" ? "已完成" : "去完善" }}
            </div>
          </div> -->
          <template v-if="detail.appStatus && detail.appStatus != '1'">
            <!-- <div
              v-if="detail.canEvalVehicle == 'Y'"
              class="quota-item dis_flex ali_it_cen ju_con_bet"
              @click="
                handleJump({
                  processName: '车辆评估',
                  ...detail,
                  materialList
                })
              "
            >
              <div>车辆评估</div>
              <div
                :class="[
                  'home-btn',
                  `home-btn_${detail.vehicleEvaluated == 'Y' ? '1' : '0'}`
                ]"
              >
                {{ detail.vehicleEvaluated == "Y" ? "已完成" : "去评估" }}
              </div>
            </div> -->
            <!-- 待预审不展示 -->
            <div
              v-if="unAppoint"
              class="quota-item dis_flex ali_it_cen ju_con_bet"
              @click="
                handleJump({
                  processName: '联系人信息',
                  ...detail
                })
              "
            >
              <div>联系人信息</div>
              <div
                :class="[
                  'home-btn',
                  `home-btn_${detail.contactPersonSaved == 'Y' ? '1' : '0'}`
                ]"
              >
                {{ detail.contactPersonSaved == "Y" ? "已完成" : "去完善" }}
              </div>
            </div>
            <div
              v-if="materialList && materialList.length > 0 && unAppoint"
              class="quota-item dis_flex ali_it_cen ju_con_bet"
              @click="
                handleJump({
                  processName: '进件材料',
                  ...detail
                })
              "
            >
              <div>进件材料</div>
              <div
                :class="[
                  'home-btn',
                  `home-btn_${detail.applyImagesSaved == 'Y' ? '1' : '0'}`
                ]"
              >
                {{ detail.applyImagesSaved == "Y" ? "已完成" : "去完善" }}
              </div>
            </div>
            <div
              v-if="customerServiceStatus == 'Y'"
              class="quota-item dis_flex ali_it_cen ju_con_bet"
              @click="
                handleJump({
                  processName: '专属客服',
                  ...detail
                })
              "
            >
              <div>专属客服</div>
              <div
                :class="[
                  'home-btn',
                  `home-btn_${detail.additionStatus == '1' ? '1' : '0'}`
                ]"
              >
                {{ detail.additionStatus == "1" ? "已添加" : "去添加" }}
              </div>
            </div>
            <!-- 关注 公众号  独立，不受车辆评估  限制 -->
            <!-- 产品说 这里不限制打开，任何状态都可以打开 -->
            <div
              v-if="detail.isSubscribe == 'Y'"
              class="quota-item dis_flex ali_it_cen ju_con_bet"
              @click="
                handleJump({
                  processName: '关注公众号',
                  ...detail
                })
              "
            >
              <div>关注公众号</div>
              <div
                :class="[
                  'home-btn',
                  `home-btn_${detail.subscribeStatus == '已关注' ? '1' : '0'}`
                ]"
              >
                {{ detail.subscribeStatus }}
              </div>
            </div>
          </template>
          <!-- <div v-else class="btns">
            <van-button type="info" size="small" @click="saveFn"
              >刷新</van-button
            >
          </div> -->
          <div class="bottom-tips">下拉即可刷新页面</div>
        </div>
      </template>
      <template v-else>
        <div class="dis_flex ali_it_cen ju_con_cen m_t_48 font_36">
          请从短信链接进入
        </div>
      </template>

      <!-- 进件抽屉 -->
      <FillInvUpload
        v-model="fillUpload"
        :detail="detail"
        @update="orderDetail"
      />

      <!-- 车评估抽屉 -->
      <FillCarEval
        v-model="fillCarEval"
        :unAppoint="unAppoint"
        :detail="detail"
        @update="orderDetail"
      />

      <!-- 联系人抽屉 -->
      <FillContact
        v-model="fillContact"
        :detail="detail"
        @update="orderDetail"
      />

      <!--  专属客服抽屉  -->
      <Service v-model="serviceShow" :bizNo="bizNo" :url="url" />
      <!-- 填写信息弹层 -->
      <FillIn
        v-model="fillView"
        :blockKey="blockKey"
        :blockName="blockName"
        :blockFields="blockFields"
        @updateData="updateData"
      />
    </div>
  </van-pull-refresh>
</template>

<script>
import { orderDetail, getServiceQRCode, productConfigs } from "@/api";
import { mapActions, mapGetters } from "vuex";
import { BIZINFO, LOGIN_EXPIRED } from "@/utils/token";
import { getProductMaterialConfigByBizNo } from "@/api";
import { Notify } from "vant";
import { Dialog } from "vant";
import { copyToClipboard } from "@/utils/tool.js";
import { celarExpire, clearCache } from "@/utils/app";
export default {
  components: {
    FillInvUpload: () => import("@/views/home/components/FillInvUpload.vue"),
    FillCarEval: () => import("@/views/Index/components/FillCarEval.vue"),
    FillContact: () => import("@/views/Index/components/FillContact.vue"),
    FillIn: () => import("@/views/home/components/FillInv3.vue"),
    Service: () => import("./components/Service.vue")
  },
  data() {
    return {
      pageLoading: true,
      bizNo: null,
      materialList: [], // 该订单关联产品所配置
      fillUpload: false, // 影像件上传  弹层
      fillContact: false, // 联系人 抽屉
      serviceShow: false, // 专属客服

      fillCarEval: false, // 车辆评估
      detail: {},
      intervalCount: 720, // 1h还是刷不出来则判定为不再刷新
      isPullLoading: false, // 下拉刷新
      setTimeoutObj: null, // 轮询定时器
      url: "", // 二维码
      customerServiceStatus: "",
      cacheData: {},
      blockKey: "", // 填写信息对应的 sessionStorage key；每次编辑某个模块时变动
      blockName: "",
      blockFields: [],
      fillView: false, // 填写信息弹层
      infosFields: [] // 需要填写信息的模块及字段
    };
  },
  created() {},

  mounted() {
    // 由于一个用户，同一个产品，可以申请多个订单。
    // 因此，需要根据后端发短信，url携带订单号，来获取订单详情。
    // 数据流动设计：这里的设计，只需要记住。我进入这个订单详情页，路由一定要携带bizNo
    // 数据流动设计：然后从订单详情页派发到其他页面，如果需要用到bizNo，就从store里面取。
    // 数据流动设计：这里要和前面申请页的信息缓存的数据隔离开来，即不适用_app_cache_data_，
    // 数据流动设计：因为依照后端所说，登录的用户信息和申请页的用户信息是分开的，匹配不上。
    // 数据流动设计：所以，这里的数据，只和后端发短信的url有关，和登录的用户信息无关。这样为了防止用户用自己的账号去篡改其他用户的数据
    this.bizNo = this.$route.query?.bizNo ?? undefined;
    this.intervalCount = 720;
    this.setBizNoQuery(this.bizNo); // 往store里面存bizNo

    const data = localStorage.getItem("_app_cache_data_") || "{}";

    this.cacheData = JSON.parse(data);
    this.judgeLoginInfo();
  },
  beforeDestroy() {
    if (this.setTimeoutObj) {
      clearTimeout(this.setTimeoutObj);
    }
  },
  computed: {
    isWechat() {
      return /MicroMessenger/i.test(navigator.userAgent);
    },
    unAppoint() {
      const merchantNo = this.cacheData.merchantNo;
      return ![
        "503024", // 生产指定商户
        "113035" // 测试环境指定商户
      ].includes(merchantNo);
    }
  },
  watch: {},
  methods: {
    ...mapActions(["setBizNoQuery"]),
    ...mapGetters(["getBizNoQuery"]),

    // 判断是不是别人盗取链接来进入的页面
    async judgeLoginInfo() {
      if (this.bizNo) {
        const data = true;
        if (data) {
          // 展示详情
          this.orderDetail();
          return;
        }
      }
    },
    gobackLogin() {
      this.$toast.fail("登录信息不匹配，请重新登录");
      localStorage.setItem("_app_cache_data_", null);
      localStorage.setItem(LOGIN_EXPIRED, null);
      this.$router.push("/login");
    },
    // 订单详情
    async orderDetail() {
      try {
        this.pageLoading = true;

        if (this.bizNo) {
          this.getUrl();
          const { data } = await orderDetail(this.bizNo);
          if (!data) return;
          this.detail = data;

          this.getProductConfigs();
          // 从订单详情里取出数据，存储本地缓存，用于其他页面。
          const localData = {
            bizNo: this.bizNo,
            certNo: data.certNo, // 用户的身份证号码以申请单为准
            customerName: data.customerName // 重写一遍
          };

          // 四要素认证成功以后，会更新进件信息
          localStorage.setItem(BIZINFO, JSON.stringify(localData));

          // 进件影像件材料上传数组
          const resp2 = await getProductMaterialConfigByBizNo(this.bizNo);

          this.materialList = resp2?.data ?? [];
          // 清空
          Notify.clear();
          // 处理几个需要提示的状态
          if (this.detail.appStatus == "1") {
            Notify({
              type: "primary",
              message: "平台预审中,请勿关闭页面",
              duration: 0
            });

            if (this.setTimeoutObj) clearTimeout(this.setTimeoutObj);
            if (this.intervalCount > 0) {
              // 1h内，如果超过1h还是没刷出来结果就默认不刷新
              const that = this;
              that.intervalCount--;
              this.setTimeoutObj = setTimeout(() => {
                that.orderDetail();
              }, 5 * 1000); // 5s刷新一次
            }
          } else if (this.detail.appStatus == "2") {
            Notify({
              type: "success",
              message: "预审通过，请继续完善申请信息",
              duration: 0
            });
          } else if (this.detail.appStatus == "-1") {
            Notify({ type: "danger", message: "预审未通过", duration: 0 });
          }
        }
      } catch (error) {
        // 产品说，请求接口抛错，则不再轮询
        if (this.setTimeoutObj) clearTimeout(this.setTimeoutObj);
        this.$errMsg(error);
      } finally {
        this.isPullLoading = false; // 下拉刷新
        this.pageLoading = false;
      }
    },
    // 权限及url
    async getUrl() {
      try {
        const { data } = await getServiceQRCode(this.bizNo);
        this.url = data?.customerServiceUrl || "";
        this.customerServiceStatus = data?.customerServiceStatus || "";
      } catch (error) {
        this.$errMsg(error);
      }
    },
    handleJump(item) {
      // const fillComplete = this.infosFields.every((e) => e.complete);
      try {
        if (item.processName == "车辆评估" && item.vehicleEvaluated != "Y") {
          // 能进来这里，说明已经是预审通过之后的状态
          // if (item.contactPersonSaved != "Y") {
          //   throw "请先填写联系人信息";
          // }
          // 如果进件材料配置存在，且没有填写进件材料
          // if (
          //   item.materialList &&
          //   item.materialList.length > 0 &&
          //   item.applyImagesSaved != "Y"
          // ) {
          //   throw "请先填写进件材料";
          // }
          this.fillCarEval = true;
          return;
        }
        // if (!fillComplete && item.processName != "车辆评估")
        //   throw "请先完成申请信息内容";

        if (item.processName == "还款卡绑定" && item.processStatus == "-1") {
          this.$router.push("/bankCard");
        } else if (
          item.processName == "联系人信息" &&
          item.contactPersonSaved != "Y"
        ) {
          this.fillContact = true;
        } else if (
          item.processName == "进件材料" &&
          item.applyImagesSaved != "Y"
        ) {
          // 能进来这里，说明已经是预审通过之后的状态+进件材料存在
          // 只需要校验联系人信息已经填完就行
          // if (item.contactPersonSaved != "Y") {
          //   throw "请先填写联系人信息";
          // }
          this.fillUpload = true;
        } else if (
          item.processName == "专属客服" &&
          item.additionStatus == "-1"
        ) {
          // if (
          //   this.detail.canEvalVehicle == "Y" &&
          //   this.detail.vehicleEvaluated != "Y"
          // ) {
          //   throw "请先完成车辆评估";
          // } else {
          //   this.serviceShow = true;
          // }

          this.serviceShow = true;
        } else if (item.processName == "关注公众号") {
          if (this.isWechat) {
            if (this.bizNo && this.detail.merchantNo) {
              this.$router.push(
                `/wxOfficialAccount?bizNo=${this.bizNo}&merchantNo=${this.detail.merchantNo}`
              );
            } else {
              console.log("缺少bizNo");
            }
          } else {
            Dialog.confirm({
              title: "提示",
              message: "请复制链接到微信打开",
              confirmButtonText: "复制链接",
              cancelButtonText: "取消"
            })
              .then(() => {
                copyToClipboard(location.href);

                this.$toast.success("复制成功");
              })
              .catch(() => {
                // on cancel
              });
          }
        }
      } catch (error) {
        console.log("error", error);
        this.$errMsg(error);
      }
    },
    openFieldsUploadEdit() {
      this.fillUpload = true;
    },
    // 产品配置
    async getProductConfigs() {
      try {
        this.pageLoading = true;
        const { productNo } = this.cacheData;
        const { data } = await productConfigs(productNo);
        // 筛选出能用的表单
        this.infosFields = data
          .filter((e) => {
            const _has = e.attributeDTOList && e.attributeDTOList.length;
            const _has2 = (e.attributeDTOList || []).filter(
              (e2) => e2.status == "Y"
            ).length;
            return _has && _has2;
          })
          // .filter((e) => {
          //   const index = this.detail.processInfoList.findIndex(
          //     (d) => d.processName == e.qualificationName
          //   );
          //   // if(index >= 0)
          //   return index < 0;
          // })
          .map((e) => {
            const index = this.detail.processInfoList.findIndex(
              (d) => d.processName == e.qualificationName
            );
            const complete = index >= 0;
            return {
              ...e,
              complete,
              name: e.qualificationName,
              textvalue: complete ? "已完成" : "",
              tips: complete ? "已完成" : "去完善"
            };
          });
        this.infosFields = [...this.infosFields];
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    // 打开编辑弹出层
    openFieldsEdit({
      qualificationKey,
      qualificationName,
      attributeDTOList,
      complete
    }) {
      if (complete) return;
      this.blockKey = qualificationKey;
      this.blockFields = attributeDTOList;
      this.blockName = qualificationName;
      this.fillView = true;
    },
    // 更新本地信息
    updateData(key, data, cacheData) {
      let block = this.infosFields.find((e) => e.qualificationKey == key);
      const keys = {
        BASIC_INFORMATION: "basicInfomationMap", // 基础
        PROPERTY_INFORMATION: "propertyInfomationMap", // 房产
        VEHICLE_PRODUCTION_INFORMATION: "vehicleProductionInfomationMap",
        WORK_INFORMATION: "workInfomationMap",
        APPLY_EQUIPMENT: "applyEquipmentMap"
      };
      const index = this.infosFields.findIndex(
        (e) => e.qualificationKey == key
      );
      block.complete = true;
      // block.form = data;
      // this.fieldsForm[keys[key]] = data;
      block.textvalue = "已完成"; // 展示用
      // this.cacheKeys.map((key) => {
      //   this.$set(this.cacheData, key, cacheData[key] || this.cacheData[key]);
      // });
      this.$set(this.infosFields, index, block);
    },
    logout() {
      const str = localStorage.getItem("_app_cache_data_") || "{}";

      const data = JSON.parse(str);
      const { productNo, merchantNo } = data;

      celarExpire();
      clearCache();

      localStorage.setItem("_app_cache_data_", null);
      localStorage.setItem(LOGIN_EXPIRED, null);
      this.$router.push(
        `/login?productNo=${productNo}&merchantNo=${merchantNo}`
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.home-content {
  // overflow: hidden;
  padding-bottom: 48px;
  .m_t_60 {
    margin-top: 60px;
  }
  .banner {
    width: 100%;
    height: 286px;
    background: url("/images/index/背景.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 16px 24px;
    box-sizing: border-box;
    .logout {
      font-size: 24px;
      background-color: #f7dfe2aa;
      border: 1px solid #f8808e;
      color: #ee0a24;
      padding: 0 12px;
      margin-left: 16px;
      border-radius: 8px;
    }

    color: #fff;
    font-weight: bold;
    .font_numb {
      font-size: 58px;
    }

    .main-info-bloc {
      width: 600px;
      overflow: hidden;
    }
    .divider {
      width: 2px;
      background-color: #f2f5f5;
      height: 40px;
      margin: 0px 36px;
    }
  }
  .apply-info {
    margin-top: 40px;
    position: relative;
    margin-bottom: 40px;
    padding-top: 40px;
    .info-title {
      width: 368px;
      height: 64px;
      margin-top: -48px;
      background: url("/images/login/title@2x.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 64px;
      font-size: 32px;
      font-weight: 600;
      margin-left: calc((100% - 368px) / 2);
      color: #ffffff;
    }
  }
  .bottom-tips {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #999999;
    padding: 10px 0px;
  }
  .btns {
    position: fixed;
    bottom: 0px;
    left: 0px;
    border-top: 1px solid #efefef;
    width: 100%;

    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -5px 10px #efefef;
    overflow: hidden;
    background-color: #ffffff;
    padding: 10px 12px;
    .van-button {
      min-width: 160px;
      flex: 1;
    }
  }

  .container {
    position: relative;
    // top: -124px;

    z-index: 20;

    width: 702px;
    // max-height: calc(100vh - 456px);
    // overflow-y: auto;
    // overflow-x: hidden;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 30, 103, 0.08);
    border-radius: 16px;

    .quota-item {
      padding: 34px 0px;
      border-bottom: 2px solid #f2f5f5;
      &:last-child {
        border-bottom: none;
      }

      // 用户填写列表的按钮状态颜色
      .home-btn {
        border: 2px solid;
        border-color: #2b8eff;
        color: #2b8eff;
        font-size: 24px;
        // height: 40px;
        // line-height: 39px;
        border-radius: 22px;

        padding: 4px 32px;
        box-sizing: border-box;

        &_1 {
          border-color: #66bb6a;
          color: #66bb6a;
        }
        &_-1 {
          border-color: #2b8eff;
          color: #2b8eff;
        }
        &_Warning {
          border-color: #f59a23;
          color: #f59a23;
        }

        &.SUCESS,
        &.2 {
          border-color: #66bb6a;
          color: #66bb6a;
        }
        &.CXP,
        &.back {
          border-color: #d9001b;
          color: #d9001b;
        }
      }
    }
  }
}
</style>
